@font-face {
    font-family: 'Proto Grotesk';
    font-weight: 700;
    src: local('Proto Grotesk'), url(./assets/fonts/ProtoGrotesk-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'Proto Grotesk';
    font-weight: 400;
    src: local('Proto Grotesk'), url(./assets/fonts/ProtoGrotesk-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'Forever Grotesk';
    font-weight: 100;
    src: local('Forever Grotesk'), url(./assets/fonts/NNForeverGroteskSTD-Light.otf) format('opentype');
}

body {
    margin: 0;
    font-family: 'Barlow', 'Proto Grotesk', 'Forever Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
    background-image: url(./assets/images/evento/bkg.jpg) !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    max-height: 100vh;
    min-height: -webkit-fill-available;
    -webkit-user-select: none;
    user-select: none;
}

html {
    height: -webkit-fill-available;
}

.vote {
    height: -webkit-fill-available;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.particle {
    position: absolute;
    will-change: transform;
    overflow: hidden;
}
